import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';
import { SurveyModel } from 'survey-core';
import { useCallback, useEffect, useState } from 'react';

import { Typography } from 'src/shared/ui/typography';
import { useGetSurveyAnswersByFormIdQuery, useGetSurveyContentQuery } from 'src/store/api';
import { not, showToastErrorMessage } from 'src/shared/utils';
import { BackButton } from 'src/shared/ui/backButton';
import {
  useDeleteSurveyAnswerMutation,
  useLazyGetSurveyAnswersByFormIdQuery,
} from 'src/store/api/surveyAnswer';
import { Spinner } from 'src/shared/ui/spinner';
import {
  SurveyAnswerEntity,
  PageSize,
  SortBy,
  SortOrder,
  SurveyElement,
  SurveyContentJSON,
} from 'src/shared/types';
import { Pagination } from 'src/shared/ui/pagination';
import { useDebounce } from 'src/shared/hooks/useDebounce';
import { PAGE_SIZE_OPTIONS } from 'src/shared/constants/pagination';
import { Button } from 'src/shared/ui/button';
import { useToggle } from 'src/shared/hooks/useToggle';
import { MAX_ANSWERS_TO_EXPORT } from 'src/config';

import { DeleteSurveyAnswerModal } from './Features/DeleteSurveyAnswerModal';
import {
  SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID,
  VisualizationPanel,
} from './Features/VisualizationPanel';
import { SearchField } from './Features/SearchField';
import { getAdaptedTabulatorAnswers } from './adapters';
import './style.css';
import { DisplayedQuestionsModal } from './Features/DisplayedQuestionsModal';
import { ExtraSurveyAnswerField } from './constants';

const SEARCH_FIELD_DEBOUNCE_DELAY = 500;
const DEFAULT_DISPLAYED_QUESTIONS_COUNT = 10;

const SurveyAnswers = () => {
  const { formId } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [formQuestions, setFormQuestions] = useState([] as SurveyElement[]);
  const [displayedQuestions, setDisplayedQuestions] = useState([] as string[]);
  const [isQuestionsModalOpen, toggleQuestionsModal, setQuestionsModalOpen] = useToggle(false);
  const [isExportingCSV, setExportingCSV] = useState(false);

  const getPageSize = (size: string | null): PageSize => {
    const parsed = parseInt(size || '5', 10);

    if (PAGE_SIZE_OPTIONS.includes(parsed as PageSize)) {
      return parsed as PageSize;
    }

    return 5 as PageSize;
  };

  const [pageSize, setPageSize] = useState<PageSize>(getPageSize(searchParams.get('pageSize')));
  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1', 10));
  const [searchField, setSearchField] = useState<string>(searchParams.get('search') || '');
  const [survey, setSurvey] = useState<SurveyModel | null>(null);
  const [sort, setSort] = useState<SortBy>({
    fieldKey: 'createdAt',
    order: SortOrder.DESC,
  });

  const debouncedSearchField = useDebounce(searchField, SEARCH_FIELD_DEBOUNCE_DELAY);

  const { data: form, isFetching: isLoadingForm } = useGetSurveyContentQuery(
    {
      id: formId || '',
      normalizedForAnswersPage: true,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const pathData = displayedQuestions.filter(
    (question) => !(Object.values(ExtraSurveyAnswerField) as string[]).includes(question),
  );

  const sharedAnswersParams = {
    formId: formId ?? '',
    filters: {
      searchField: debouncedSearchField.trim(),
      path: pathData,
    },
    sort: {
      fieldKey: sort.fieldKey,
      order: sort.order,
    },
  };

  const {
    data = {
      data: [],
      total: 0,
      totalPages: 0,
    },
    isFetching: isFetchingAnswers,
  } = useGetSurveyAnswersByFormIdQuery(
    {
      ...sharedAnswersParams,
      pagination: {
        pageSize: searchParams.get('pageSize') || '5',
        page: searchParams.get('page') || '1',
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !formId,
    },
  );

  const [fetchSurveyAnswersLazy] = useLazyGetSurveyAnswersByFormIdQuery();

  const { data: answers, total } = data;

  const [deleteAnswer, { isLoading: isDeletingAnswer }] = useDeleteSurveyAnswerMutation();

  const [isDeleteSurveyAnswerModalOpen, setIsDeleteSurveyAnswerModalOpen] = useState(false);
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState<SurveyAnswerEntity | undefined>(
    undefined,
  );

  const openQuestionsModal = () => {
    setQuestionsModalOpen(true);
  };

  const closeQuestionsModal = () => {
    setQuestionsModalOpen(false);
  };

  useEffect(() => {
    if (!form) {
      return;
    }

    const paginatedQuestions = ((form.content as SurveyContentJSON).pages
      ?.map((page) => page.elements)
      .flat()
      .map(({ name, title }) => ({
        name,
        title,
      })) || []) as SurveyElement[];

    const extraFields = Object.values(ExtraSurveyAnswerField).map((name) => ({ name }));

    const questions = [...paginatedQuestions, ...extraFields];

    const defaultDisplayedQuestions = [
      ...questions.slice(0, DEFAULT_DISPLAYED_QUESTIONS_COUNT),
      ...extraFields,
    ].map(({ name }) => name);

    setFormQuestions(questions);
    setDisplayedQuestions(defaultDisplayedQuestions);
  }, [form]);

  const handleNavigationClick = (href: string) => {
    navigate(href);
  };

  const handleSearchFieldChange = (searchFieldValue: string) => {
    setSearchField(searchFieldValue);
    setPage(1);

    if (!searchFieldValue) {
      const newSearchParams = new URLSearchParams(searchParams.toString());

      newSearchParams.delete('search');

      setSearchParams(newSearchParams);

      return;
    }
    if (searchFieldValue) {
      setSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        search: searchFieldValue,
      });
    }
  };

  const handlePageChange = (pageValue: number) => {
    setPage(pageValue);
    setSearchParams({
      page: pageValue.toString(),
      pageSize: pageSize.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handlePageSizeChange = (pageSizeValue: PageSize) => {
    setPageSize(pageSizeValue);
    setPage(1);
    setSearchParams({
      page: String(1),
      pageSize: pageSizeValue.toString(),
      ...(searchField && { search: searchField }),
    });
  };

  const handleDownloadClick = async () => {
    if (!survey) {
      showToastErrorMessage('Survey in not initialized');

      return;
    }

    let downloader: Tabulator | null = null;

    try {
      setExportingCSV(true);

      const { data: { data: answersToExport = [] } = {} } = await fetchSurveyAnswersLazy({
        ...sharedAnswersParams,
        pagination: {
          pageSize: MAX_ANSWERS_TO_EXPORT,
        },
      });

      downloader = new Tabulator(survey, getAdaptedTabulatorAnswers(answersToExport), {
        downloadOptions: {
          fileName: 'results',
        },
        onDownloadCallbacks: {
          csv: () => {
            setExportingCSV(false);
          },
        },
      });
      downloader.render(SURVEY_ANSWERS_DOWNLOADER_CONTAINER_ID);
      downloader.download('csv');
      downloader.destroy();
    } catch {
      setExportingCSV(false);

      downloader?.destroy();

      showToastErrorMessage('Failed to download the survey answers');
    }
  };

  const handleSortChange = useCallback(
    ({ fieldKey, order }: SortBy) => {
      if (fieldKey !== sort.fieldKey || order !== sort.order) {
        setSort({
          fieldKey,
          order,
        });
      }
    },
    [sort, setSort],
  );

  if (not(form) && not(isLoadingForm)) {
    showToastErrorMessage('There was an error trying to load the form');

    navigate('/forms');

    return null;
  }

  return (
    <>
      {isLoadingForm && (
        <Spinner
          withBackdrop
          fallbackText="Loading form answers..."
        />
      )}

      {isDeletingAnswer && (
        <Spinner
          withBackdrop
          fallbackText="Deleting form..."
        />
      )}

      <div className="flex flex-col w-full gap-y-4 py-4 px-4">
        <div className="flex items-center gap-4 flex-wrap">
          <BackButton
            href="/forms"
            handleNavigationClick={handleNavigationClick}
          />

          <Typography variant="h1">Survey Answers</Typography>
        </div>

        <div className="w-full p-2 grid grid-cols-1 lg:grid-cols-2-2-1-1 gap-4 bg-white rounded-xl">
          <div className="w-full min-w-[300px]">
            <SearchField
              value={searchField}
              handleChange={handleSearchFieldChange}
              isFetching={isFetchingAnswers}
            />
          </div>

          <div className="w-full">
            <Pagination
              pagination={{
                currentPage: page,
                onPageChange: handlePageChange,
                onPageSizeChange: handlePageSizeChange,
                pageSize,
                total,
              }}
              className="rounded-xl"
            />
          </div>

          <Button
            className="w-full h-full"
            variant="filled"
            color="primary"
            onClick={openQuestionsModal}
          >
            Manage displayed questions
          </Button>

          <Button
            className="w-full h-full"
            variant="filled"
            color="primary"
            onClick={handleDownloadClick}
            disabled={isExportingCSV}
          >
            Download CSV
          </Button>
        </div>

        <VisualizationPanel
          form={form}
          displayedQuestions={displayedQuestions}
          answers={answers}
          deleteAnswer={deleteAnswer}
          setIsDeleteSurveyAnswerModalOpen={setIsDeleteSurveyAnswerModalOpen}
          setSelectedSurveyAnswer={setSelectedSurveyAnswer}
          onSurveyInitialized={setSurvey}
          handleSortChange={handleSortChange}
        />
      </div>

      <DeleteSurveyAnswerModal
        isOpen={isDeleteSurveyAnswerModalOpen}
        setIsOpen={setIsDeleteSurveyAnswerModalOpen}
        answer={selectedSurveyAnswer}
      />

      <DisplayedQuestionsModal
        open={isQuestionsModalOpen}
        toggleModal={toggleQuestionsModal}
        onClose={closeQuestionsModal}
        questions={formQuestions}
        displayedQuestions={displayedQuestions}
        onChange={setDisplayedQuestions}
      />
    </>
  );
};

export { SurveyAnswers };
